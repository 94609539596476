import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { languages } from '@/models/languages'

function getWordLength(string: string) {
  return string?.split(' ')?.length ?? 0;
}

function getSourceLanguage(field: any) {
  const langAbbr = field.split('_')[field.split('_').length - 1]
  return languages?.find((language) => language.iso_code.toLowerCase() === langAbbr)
}

function getTargetLang(field: any) {
  const langAbbr = field.split('_')[field.split('_').length - 1]
  const language = languages
    .filter(language => language.iso_code.toLowerCase() !== langAbbr)
    .map(language => language.name);
  return language ?? ''
}

function getFieldName(field: any) {
  const parts = field.split('_');
  if (parts.length < 2) return null;
  const langAbbr = parts[parts.length - 1];
  const fieldName = parts.slice(0, -1).join('_');
  const language = languages
    .filter(language => language.iso_code.toLowerCase() !== langAbbr.toLowerCase())
    .map(language => `${fieldName}_${language.iso_code.toLowerCase()}`);

  return language;
}

function fieldsByLang(field: any) {
  const fieldNames = getFieldName(field);
  if (!fieldNames) return {};

  return fieldNames.reduce((acc, fieldName, index) => {
    const lang = getTargetLang(field)[index];
    acc[lang] = fieldName;
    return acc;
  }, {} as Record<any, any>);
}

const canadianAreaCodes = [
  '204', '226', '236', '249', '250', '289', '306', '343', '365', '387',
  '403', '416', '418', '431', '437', '438', '450', '506', '514', '519',
  '548', '579', '581', '587', '604', '613', '639', '647', '672', '705',
  '709', '742', '778', '780', '782', '807', '819', '825', '867', '873',
  '902', '905'
];

function isCanadianNumber(phoneNumber: string) {
  const regexPhone = /^(\+1)?\s*\(?([2-9][0-9]{2})\)?[\s.-]?([2-9][0-9]{2})[\s.-]?([0-9]{4})$/
  const match = phoneNumber.match(regexPhone);
  if (!match) return false;

  const areaCode = match[2]; // Captura el código de área
  return canadianAreaCodes.includes(areaCode);
}

export function getFormInitialVariables() {
  const { t } = useI18n()

  const containerWidth = ref(document.body.clientWidth)

  window.addEventListener('resize', () => { containerWidth.value = document.body.clientWidth });

  return {
    containerWidth,
    autosize: { minRows: 4, maxRows: 8 },
    address: {},
    provinces: ref([]),
    countriesArray: ref([]),
    formattedAddressInvalid: ref(false),
    fileSizeLimit: 20000000,
    document,
    getWordLength,
    getSourceLanguage,
    getFieldName,
    fieldsByLang,
    getTargetLang,
    isCanadianNumber
  }
}
